const white = '#FFFFFF'
const black = '#0D0F1E'
const gray = '#e6e7ec'
const green = '#78FFBE'
const purple = '#4530E2'
const yellow = '#dab544'
const blue = '#082d75'

const themeLight = {
  background: black,
  body: white,
  highlight: purple
}

const themeDark = {
  background: black,
  body: white,
  highlight: purple
}

const theme = mode => (mode === 'light' ? themeLight : themeDark)

export default theme
