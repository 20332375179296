import React from 'react'

function EthCCInstance () {
  return (
    <iframe
      title='EthCC'
      src='https://ethcc.io'
      style={{
        height: `100%`,
        width: `100%`,
        border: '0px'
      }}
    ></iframe>
  )
}

export default EthCCInstance
