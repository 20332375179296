import React from 'react'

function PoapInstance () {
  return (
    <iframe
      title='Poap'
      src='https://flowcode.com/p/NGPVYXYTK?fc=0'
      style={{
        height: `100%`,
        width: `100%`,
        border: '0px',
        backgroundColor: 'white'
      }}
    ></iframe>
  )
}

export default PoapInstance
