import React, { useContext } from 'react'
import styled from '@emotion/styled'
import Balloon from './animations/Balloon'
import EthCClogo from '../img/ethcc5/titre.svg'
import titre from '../img/ethcc5/titre.svg'
import stgermain from '../img/ethcc5/stgermain.svg'
import sponsors from '../img/ethcc5/sponsors.svg'
import sorbonne from '../img/ethcc5/sorbonne.svg'
import schedule from '../img/ethcc5/schedule.svg'
import pontoise from '../img/ethcc5/pontoise.svg'
import Poap from '../img/ethcc5/poap.svg'
import monge from '../img/ethcc5/monge.svg'
import mainstage from '../img/ethcc5/mainstage.svg'
import interspace from '../img/ethcc5/interspace.svg'
import bievre from '../img/ethcc5/bievre.svg'
import twitter from '../img/ethcc5/twitter.svg'

import { FloatingSpaceContext } from '../contexts/FloatingSpaceContext'

const ImagemapContainer = styled.div`
  // margin: auto auto;
`

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  grid-template-rows: repeat(1, auto);
  place-items: center;
  margin: auto auto;
  width: 100%;
  height: 100%;
  @media (max-width: 1400px) {
    grid-template-columns: repeat(7, 1fr);
  }
  img {
    width: 150px;
  }
  div {
    transition: 0.2s cubic-bezier(0.2, 0.8, 0.2, 1);
    border-radius: 300px;
    border: 2px solid #ffffff00;
    max-height: 80px;
    max-width: 40px;
    place-content: center;
    color: #fff;
    h1 {
      text-align: center;
    }
    h1 span {
      color: #b30602;
      font-size: 2rem;
    }
    &:hover {
      svg {
        // border: 2px solid #b30602aa;
        // transform: scale(1.2);
        filter: drop-shadow(1px 1px 4px gray);
      }
      h1 {
        color: #b30602;
      }
      .portal {
        filter: drop-shadow(8px 8px 10px gray);
      }
      .title {
        // transform: scale(1.2) translateY(40px);
        opacity: 1;
        z-index: 2;
      }
      .bleu {
        color: #082d75;
      }
      cursor: pointer;
    }
  }
  .title {
    font-size: 0.8rem;
    opacity: 0.5;
    place-self: center;
    text-align: center;
    position: relative;
    transform: translateY(60px);
    @media (max-width: 600px) {
      transform: translateY(80px);
    }
  }
  .portal {
    transition: 0.3s cubic-bezier(0.2, 0.8, 0.2, 1);
  }
`

const Cell1 = styled.div`
  //transform: scale(1.5);
  transform: translateY(80px);
  display: grid;
  grid-row: 3;
  grid-column: 2;
`

const Cell2 = styled.div`
  // transform: scale(1.5) translate(-100px, 0px);
  transform: translateY(160px);
  display: grid;
  grid-row: 3;
  grid-column: 2;
`

const Cell3 = styled.div`
  // transform: scale(1.5);
  transform: translateY(240px);
  display: grid;
  grid-row: 3;
  grid-column: 2;
`

const Cell4 = styled.div`
  // transform: scale(1.5);
  transform: translateY(240px);
  display: grid;
  grid-row: 3;
  grid-column: 6;
`

const Cell5 = styled.div`
  display: grid;
  place-content: center;
  grid-row: 2;
  grid-column: 4;
  .portal {
    // position: absolute;
    place-self: center;
    transform: scale(1.2);
    top: 33vh;
    max-height: 200px;
    max-width: 85px;
  }
  .title {
    transform: translateY(10px);
    font-size: 1.5rem;
    color: black;
    margin: 0 auto;
  }
  &:hover .title {
    opacity: 1;
  }
`
const Cell6 = styled.div`
  // transform: scale(1.5) translate(100px, 0px);
  transform: translateY(160px);
  display: grid;
  grid-row: 3;
  grid-column: 6;
`

const Cell7 = styled.div`
  // transform: scale(1.5);
  transform: translateY(80px);
  display: grid;
  grid-row: 3;
  grid-column: 6;
`

const Cell8 = styled.div`
  transform: translateY(50px);
  display: grid;
  grid-row: 1;
  grid-column: 1;
`

const Cell9 = styled.div`
  transform: translateY(0px);
  display: grid;
  grid-row: 1;
  grid-column: 1;
`

const Cell10 = styled.div`
  transform: translateY(-80px);
  display: grid;
  grid-row: 1;
  grid-column: 1;
`
const Cell11 = styled.div`
  transform: translateY(-80px);
  display: grid;
  grid-row: 1;
  grid-column: 7;
`

const Cell12 = styled.div`
  display: grid;
  grid-row: 1;
  grid-column: 7;
`

const Cell13 = styled.div`
  transform: translateY(-80px);
  display: grid;
  grid-row: 1;
  grid-column: 7;
`

const BottomLinks = styled.span`
  padding: 4rem;
  grid-row: 3;
  grid-column: 1 / span 7;
  display: grid;
  grid-template-columns: repeat(5, 150px);
  justify-self: center;
  justify-items: center;
  div {
    text-align: center;
    padding: 1rem;
    :hover {
      transform: scale(1.2);
    }
  }
`

const EthCCMobile = () => {
  const { addFloatingSpace, currentFloatingSpaces } = useContext(
    FloatingSpaceContext
  )
  const space = currentFloatingSpaces

  const openInNewTab = url => {
    let win = window.open(url, '_blank')
    win.focus()
  }

  const notYet = () => {
    alert('Live on July 19th')
  }

  const poap = () => {
    if (space.indexOf('claim poap token') > -1) {
      window.alert(
        'To claim your POAP token paste your wallet address along with your role(s) at noncon (speaker, organizer, volunteer, attendee) in the #claim-poap-token discord channel and you will receive a PM with a link to redeem.'
      )
    } else {
      window.alert(
        'To claim your POAP token paste your wallet address along with your role(s) at noncon (speaker, organizer, volunteer, attendee) in the #claim-poap-token discord channel and you will receive a PM with a link to redeem.'
      )
      addFloatingSpace('claim poap token')
    }
  }

  return (
    <ImagemapContainer>
      <Grid>
        <Cell5
          onClick={() => openInNewTab('https://ethcc.io')}
          className='click-zone'
        >
          <img className='portal' src={EthCClogo} alt='' />
        </Cell5>
        <Cell1
          onClick={() =>
            openInNewTab(
              'https://www.youtube-nocookie.com/embed/videoseries?list=PLhM7rBgpVV-JnmRLUbd10ELntN3LFfmKH'
            )
          }
        >
          <img className='portal' src={mainstage} alt='' />
        </Cell1>
        <Cell2
          onClick={() =>
            openInNewTab(
              'https://www.youtube-nocookie.com/embed/videoseries?list=PLSJ8gU1sECuzx4mmVWQ_bw6CLxK6TyPKH'
            )
          }
        >
          <img className='portal' src={monge} alt='' />
        </Cell2>
        <Cell3
          onClick={() =>
            openInNewTab(
              'https://www.youtube-nocookie.com/embed/videoseries?list=PL-owlDp9BBasbuWJv-hiLqwqaVrpSoBKj'
            )
          }
        >
          <img className='portal' src={stgermain} alt='' />
        </Cell3>
        <Cell4
          onClick={() =>
            openInNewTab(
              'https://www.youtube-nocookie.com/embed/videoseries?list=PLM-Xjhvin-uWN9ov74srLZIuJsbRSc2ou'
            )
          }
        >
          <img className='portal' src={sorbonne} alt='' />
        </Cell4>
        <Cell6
          onClick={() =>
            openInNewTab(
              'https://www.youtube-nocookie.com/embed/videoseries?list=PLUt355rCCNrSL-n6G7vxavNv7AKdrdfkS'
            )
          }
        >
          <img className='portal' src={bievre} alt='' />
        </Cell6>
        <Cell7
          onClick={() =>
            openInNewTab(
              'https://www.youtube-nocookie.com/embed/videoseries?list=PLqL60kqgLPBBrc64K-1Gs771FBTiLtYZE'
            )
          }
        >
          <img className='portal' src={pontoise} alt='' />
        </Cell7>
        <Cell8></Cell8>
        <Cell9
          onClick={() => openInNewTab('https://flowcode.com/p/NGPVYXYTK?fc=0')}
        >
          <img className='portal' src={Poap} alt='' />
        </Cell9>
        <Cell10
          onClick={() => openInNewTab('https://twitter.com/search?q=%23ethcc')}
        >
          <img className='portal' src={twitter} alt='' />
        </Cell10>
        <Cell11></Cell11>
        <Cell12 onClick={() => openInNewTab('/sponsorships')}>
          <img className='portal' src={sponsors} alt='' />
        </Cell12>
        <Cell13 onClick={() => openInNewTab('https://ethcc.io/agenda')}>
          <img className='portal' src={schedule} alt='' />
        </Cell13>
      </Grid>
    </ImagemapContainer>
  )
}

export default EthCCMobile
