import React from 'react'
import styled from '@emotion/styled'

import SvgImagemap from './NewBalloon'

const ResponsiveBalloon = styled(SvgImagemap)`
  @media (max-width: 600px) {
    transform: scale(0.5);
  }
`

const Balloon = props => {
  const { color, width, height } = props

  const style = {
    height: `${height}`,
    width: `${width}`
  }

  return <ResponsiveBalloon color={color} style={style} />
}

export default Balloon
