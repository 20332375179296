import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import * as serviceWorker from './serviceWorker'
import { Global, css } from '@emotion/core'
import { ThemeProvider } from './contexts/ThemeContext'

import WebFont from 'webfontloader'

import './styles/init.css'

WebFont.load({
  google: {
    families: ['Roboto+Mono:100,300,400,500,600,700', 'monospace']
  }
})

ReactDOM.render(
  <ThemeProvider>
    <Global
      styles={css`
        * {
          margin: 0px;
          padding: 0px;
          font-family: 'Roboto Mono', monospace;
          font-weight: 600;
          font-size: 14px;
        }
        body {
          margin: 0px;
          padding: 0px;
          color: #fff;
          background-color: #0f1126;
        }
        .hidden {
          opacity: 0;
          filter: url(#blur0);
        }
        .inactive {
          filter: url(#saturate);
        }

        .click-zone-mobile {
          transition: 4s cubic-bezier(0.2, 0.8, 0.2, 1);

          & :focus {
            cursor: pointer;
            filter: hue-rotate(90deg);

            & .hidden {
              opacity: 1;
            }
          }
        }

        .title :hover {
          fill: black;
        }
        4530e2 .unselectable :hover {
          -moz-user-select: -moz-none;
          -khtml-user-select: none;
          -webkit-user-select: none;
          -o-user-select: none;
          user-select: none;
          cursor: pointer;
          fill: #fff;
        }
      `}
    />
    <App />
  </ThemeProvider>,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
