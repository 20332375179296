import React, { Fragment, useContext, useState, useEffect } from 'react'
import styled from '@emotion/styled'

// Gold Sponsorhip Tier
import AnomaLogo from '../img/ethcc5-sponsors/Gold/Anoma.png'

// Silver Sponsorship Tier
import AaveGrantsLogo from '../img/ethcc5-sponsors/Silver/AaveGrants.png'
import CentrifugeLogo from '../img/ethcc5-sponsors/Silver/Centrifuge.png'
import ComethLogo from '../img/ethcc5-sponsors/Silver/Cometh.png'
import DeFiSaverLogo from '../img/ethcc5-sponsors/Silver/DeFiSaver.png'
import POAPLogo from '../img/ethcc5-sponsors/Silver/POAP.png'
import SorareLogo from '../img/ethcc5-sponsors/Silver/Sorare.png'
import YAPLogo from '../img/ethcc5-sponsors/Silver/YAP.png'

// Bronze Sponsorship Tier
import OneinchLogo from '../img/ethcc5-sponsors/Bronze/1inch.png'
import API3Logo from '../img/ethcc5-sponsors/Bronze/API3.png'
import BrainbotLogo from '../img/ethcc5-sponsors/Bronze/Brainbot.png'
import ChainlinkLogo from '../img/ethcc5-sponsors/Bronze/Chainlink.png'
import IExecLogo from '../img/ethcc5-sponsors/Bronze/iExec.png'
import LEDGERLogo from '../img/ethcc5-sponsors/Bronze/LEDGER.png'
import MyEtherWalletLogo from '../img/ethcc5-sponsors/Bronze/MyEtherWallet.png'
import ParaSwapLogo from '../img/ethcc5-sponsors/Bronze/ParaSwap.png'
import PolygonLogo from '../img/ethcc5-sponsors/Bronze/Polygon.png'
import StakeDAOLogo from '../img/ethcc5-sponsors/Bronze/StakeDAO.png'
import StarkwareLogo from '../img/ethcc5-sponsors/Bronze/Starkware.png'
import TheGraphLogo from '../img/ethcc5-sponsors/Bronze/TheGraph.png'
import WitnetLogo from '../img/ethcc5-sponsors/Bronze/Witnet.png'

// Mithril Sponsorship
import APWimeLogo from '../img/ethcc5-sponsors/Mithril/APWime.png'
import AuroraLogo from '../img/ethcc5-sponsors/Mithril/Aurora.png'
import BosonProtocolLogo from '../img/ethcc5-sponsors/Mithril/BosonProtocol.png'
import CowSwapLogo from '../img/ethcc5-sponsors/Mithril/CowSwap.png'
import DebridgeLogo from '../img/ethcc5-sponsors/Mithril/Debridge.png'
import FurucomboLogo from '../img/ethcc5-sponsors/Mithril/Furucombo.png'
import GelatoLogo from '../img/ethcc5-sponsors/Mithril/Gelato.png'
import GnosisSafeLogo from '../img/ethcc5-sponsors/Mithril/gnosissafe.png'
import halbornLogo from '../img/ethcc5-sponsors/Mithril/halborn.png'
import HarmonyLogo from '../img/ethcc5-sponsors/Mithril/Harmony.png'
import KilnLogo from '../img/ethcc5-sponsors/Mithril/Kiln.png'
import LensLogo from '../img/ethcc5-sponsors/Mithril/Lens.png'
import LivePeerLogo from '../img/ethcc5-sponsors/Mithril/LivePeer.png'
import MetisLogo from '../img/ethcc5-sponsors/Mithril/Metis.png'
import OperaLogo from '../img/ethcc5-sponsors/Mithril/Opera.png'
import ORWLLogo from '../img/ethcc5-sponsors/Mithril/ORWL.png'
import QuantstampLogo from '../img/ethcc5-sponsors/Mithril/Quantstamp.png'
import SISMOLogo from '../img/ethcc5-sponsors/Mithril/SISMO.png'

// Iron Sponsorship Tier
import AlephLogo from '../img/ethcc5-sponsors/Iron/Aleph.png'
import AnkrLogo from '../img/ethcc5-sponsors/Iron/Ankr.png'
import blockdaemonLogo from '../img/ethcc5-sponsors/Iron/blockdaemon.png'
import CERTORALogo from '../img/ethcc5-sponsors/Iron/CERTORA.png'
import FinoaLogo from '../img/ethcc5-sponsors/Iron/Finoa.png'
import KaikoLogo from '../img/ethcc5-sponsors/Iron/Kaiko.png'
import KlerosLogo from '../img/ethcc5-sponsors/Iron/Kleros.png'
import KYVELogo from '../img/ethcc5-sponsors/Iron/KYVE.png'
import OVRLogo from '../img/ethcc5-sponsors/Iron/OVR.png'
import PoolDataLogo from '../img/ethcc5-sponsors/Iron/PoolData.png'
import PWNFinanceLogo from '../img/ethcc5-sponsors/Iron/PWNFinance.png'

const SponsorContainer = styled.div`
  width: 100%;
  height: 90%;
  background-color: #fff;
  display: grid;
  overflow-y: auto;
  flex-direction: column;
  place-content: center;
  .smaller {
    transform: scale(0.5);
  }
`

const PoweredByGold = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  grid-template-columns: repeat(1, auto);
  margin: 0 auto;
  align-items: center;
  padding-top: 2rem;
  img {
    padding: 0.5rem;
    width: 110px;
    height: auto;
    &:hover {
      transform: scale(2);
      transition: 1s cubic-bezier(0.2, 0.8, 0.2, 1);
    }
  }
`

const PoweredBySilver = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  grid-template-columns: repeat(6, auto);
  margin: 0 auto;
  align-items: center;
  padding: 0.5rem;
  grid-gap: 1rem;
  img {
    cplace-self: center;
    padding: 0.5rem;
    &:hover {
      transform: scale(2);
      transition: 1s cubic-bezier(0.2, 0.8, 0.2, 1);
    }
  }
`

const PoweredByBronze = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  grid-template-columns: repeat(10, auto);
  margin: 0 auto;
  align-items: center;
  padding: 1rem;
  grid-gap: 0.5rem;
  img {
    place-self: center;
    width: 70px;
    height: auto;
    padding: 0.5rem;
    &:hover {
      transform: scale(2);
      transition: 1s cubic-bezier(0.2, 0.8, 0.2, 1);
    }
  }
`

const PoweredByMithril = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  grid-template-columns: repeat(3, 1fr);
  margin: 0 auto;
  align-items: center;
  padding: 1rem;
  grid-gap: 0.5rem;
  img {
    width: 60px;
    height: auto;
    place-self: center;
    padding: 0.5rem;
    &:hover {
      transform: scale(2);
      transition: 1s cubic-bezier(0.2, 0.8, 0.2, 1);
    }
  }
`

const PoweredByIron = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  grid-template-columns: repeat(3, 1fr);
  margin: 0 auto;
  align-items: center;
  padding: 1rem;
  grid-gap: 0.5rem;
  img {
    width: 50px;
    height: auto;
    place-self: center;
    padding: 0.5rem;
    &:hover {
      transform: scale(2);
      transition: 1s cubic-bezier(0.2, 0.8, 0.2, 1);
    }
  }
`

const Provider = styled.img`
  transition: 0.5s cubic-bezier(0.2, 0.8, 0.2, 1);
  filter: saturation(0.6);
  cursor: pointer;
  padding: 0.5rem;

  :hover {
    filter: saturation(1);
    transform: scale(2);
    transition: 1s cubic-bezier(0.2, 0.8, 0.2, 1);
    background: #ffffffcc;
    border-radius: 5px;
  }
`

const SponsorshipInstance = () => {
  const openInNewTab = url => {
    let win = window.open(url, '_blank')
    win.focus()
  }
  return (
    <SponsorContainer>
      <PoweredByGold>
        <Provider
          src={AnomaLogo}
          width='auto'
          height='60px'
          alt='Anoma'
          onClick={() => openInNewTab('https://anoma.network/')}
        />
      </PoweredByGold>
      <PoweredBySilver>
        <Provider
          src={AaveGrantsLogo}
          width='auto'
          height='25px'
          alt='Aave'
          onClick={() => openInNewTab('https://aavegrants.org/')}
        />
        <Provider
          src={CentrifugeLogo}
          width='auto'
          height='35px'
          alt='Centrifuge'
          onClick={() => openInNewTab('https://centrifuge.io/')}
        />
        <Provider
          src={ComethLogo}
          width='auto'
          height='35px'
          alt='Cometh'
          onClick={() => openInNewTab('https://www.cometh.io/')}
        />
        <Provider
          src={DeFiSaverLogo}
          width='auto'
          height='35px'
          alt='DeFi Saver'
          onClick={() => openInNewTab('https://defisaver.com/')}
        />
        <Provider
          src={POAPLogo}
          width='auto'
          height='20px'
          alt='POAP'
          onClick={() => openInNewTab('https://poap.xyz/')}
        />
        <Provider
          src={SorareLogo}
          width='auto'
          height='35px'
          alt='Sorare'
          onClick={() => openInNewTab('https://sorare.com/')}
        />
        <Provider
          src={YAPLogo}
          width='auto'
          height='35px'
          alt='Yap Global'
          onClick={() => openInNewTab('https://yapglobal.com/')}
        />
      </PoweredBySilver>
      <PoweredByBronze>
        <Provider
          src={API3Logo}
          width='auto'
          height='30px'
          alt='Api3'
          onClick={() => openInNewTab('https://api3.org/')}
        />
        <Provider
          src={ParaSwapLogo}
          width='auto'
          height='30px'
          alt='Paraswap'
          onClick={() => openInNewTab('https://paraswap.io/')}
        />
        <Provider
          src={BrainbotLogo}
          width='auto'
          height='30px'
          alt='Brainbot'
          onClick={() => openInNewTab('https://www.brainbot.com/')}
        />
        <Provider
          src={LEDGERLogo}
          width='auto'
          height='30px'
          alt='Raiden'
          onClick={() => openInNewTab('https://www.ledger.com/')}
        />
        <Provider
          src={WitnetLogo}
          width='auto'
          height='30px'
          alt='Witnet'
          onClick={() => openInNewTab('https://witnet.io/')}
        />
        <Provider
          src={StakeDAOLogo}
          width='auto'
          height='30px'
          alt='Stake DAO'
          onClick={() => openInNewTab('https://stakedao.org/')}
        />
        <Provider
          src={MyEtherWalletLogo}
          width='auto'
          height='30px'
          alt='Boson Protocol'
          onClick={() => openInNewTab('https://myetherwallet.com/')}
        />
        <Provider
          src={ChainlinkLogo}
          width='auto'
          height='30px'
          alt='Chainlink'
          onClick={() => openInNewTab('https://chain.link/')}
        />
        <Provider
          src={OneinchLogo}
          width='auto'
          height='30px'
          alt='1inch Exchange'
          onClick={() => openInNewTab('https://1inch.io/')}
        />
        <Provider
          src={PolygonLogo}
          width='auto'
          height='20px'
          alt='Polygon'
          onClick={() => openInNewTab('https://polygon.technology/')}
        />
        <Provider
          src={TheGraphLogo}
          width='auto'
          height='30px'
          alt='Celo'
          onClick={() => openInNewTab('https://thegraph.com/en/')}
        />
        <Provider
          src={StarkwareLogo}
          width='auto'
          height='30px'
          alt='Starkware'
          onClick={() => openInNewTab('https://starkware.co/')}
        />
        <Provider
          src={IExecLogo}
          width='auto'
          height='30px'
          alt='Iexec'
          className='smaller'
          onClick={() => openInNewTab('https://iex.ec/')}
        />
      </PoweredByBronze>
      <PoweredByMithril>
        <Provider
          src={APWimeLogo}
          width='auto'
          height='30px'
          alt='APWine'
          onClick={() => openInNewTab('https://www.apwine.fi/')}
        />
        <Provider
          src={AuroraLogo}
          width='auto'
          height='30px'
          alt='Aurora'
          onClick={() => openInNewTab('https://aurora.dev/')}
        />
        <Provider
          src={BosonProtocolLogo}
          width='auto'
          height='30px'
          alt='Boson Protocol'
          className='smaller'
          onClick={() => openInNewTab('https://www.bosonprotocol.io/')}
        />
        <Provider
          src={CowSwapLogo}
          width='auto'
          height='30px'
          alt='CowSwap'
          onClick={() => openInNewTab('https://cow.fi/')}
        />
        <Provider
          src={DebridgeLogo}
          width='auto'
          height='30px'
          alt='Debridge'
          onClick={() => openInNewTab('https://debridge.finance/')}
        />
        <Provider
          src={FurucomboLogo}
          width='auto'
          height='30px'
          alt='Furucombo'
          onClick={() => openInNewTab('https://furucombo.app/')}
        />
        <Provider
          src={GelatoLogo}
          width='auto'
          height='30px'
          alt='Gelato'
          onClick={() => openInNewTab('https://www.gelato.network/')}
        />
        <Provider
          src={GnosisSafeLogo}
          width='auto'
          height='30px'
          alt='Gnosis Safe'
          className='smaller'
          onClick={() => openInNewTab('https://gnosis-safe.io/')}
        />
        <Provider
          src={halbornLogo}
          width='auto'
          height='30px'
          alt='halborn'
          onClick={() => openInNewTab('https://halborn.com/')}
        />
        <Provider
          src={HarmonyLogo}
          width='auto'
          height='30px'
          alt='Harmony'
          onClick={() => openInNewTab('https://www.harmony.one/')}
        />
        <Provider
          src={KilnLogo}
          width='auto'
          height='30px'
          alt='Kiln'
          onClick={() => openInNewTab('https://www.kiln.fi/')}
        />
        <Provider
          src={LensLogo}
          width='auto'
          height='30px'
          alt='Lens'
          className='smaller'
          onClick={() => openInNewTab('https://lens.dev/')}
        />
        <Provider
          src={LivePeerLogo}
          width='auto'
          height='30px'
          alt='LivePeer'
          onClick={() => openInNewTab('https://livepeer.org/')}
        />
        <Provider
          src={MetisLogo}
          width='auto'
          height='30px'
          alt='Metis'
          className='smaller'
          onClick={() => openInNewTab('https://www.metis.io/')}
        />
        <Provider
          src={OperaLogo}
          width='auto'
          height='30px'
          alt='Opera Crypto'
          onClick={() => openInNewTab('https://www.opera.com/fr/crypto/next')}
        />
        <Provider
          src={ORWLLogo}
          width='auto'
          height='30px'
          alt='ORWL'
          onClick={() => openInNewTab('https://www.orwl.fr/en/en-orwl/')}
        />
        <Provider
          src={SISMOLogo}
          width='auto'
          height='30px'
          alt='SISMO'
          onClick={() => openInNewTab('https://www.sismo.io/')}
        />
      </PoweredByMithril>
      <PoweredByIron>
        <Provider
          src={AlephLogo}
          width='auto'
          height='30px'
          alt='Aleph'
          onClick={() => openInNewTab('https://aleph.im/#/')}
        />
        <Provider
          src={AnkrLogo}
          width='auto'
          height='30px'
          alt='Ankr'
          onClick={() => openInNewTab('https://www.ankr.com/')}
        />
        <Provider
          src={blockdaemonLogo}
          width='auto'
          height='30px'
          alt='blockdaemon'
          onClick={() => openInNewTab('https://blockdaemon.com/')}
        />
        <Provider
          src={CERTORALogo}
          width='auto'
          height='25px'
          alt='CERTORA'
          onClick={() => openInNewTab('https://www.certora.com/')}
        />
        <Provider
          src={FinoaLogo}
          width='auto'
          height='25px'
          alt='Finoa'
          onClick={() => openInNewTab('https://www.finoa.io/')}
        />
        <Provider
          src={KYVELogo}
          width='auto'
          height='30px'
          alt='KYVE'
          onClick={() => openInNewTab('https://www.kyve.network/')}
        />
        <Provider
          src={OVRLogo}
          width='auto'
          height='40px'
          alt='OVR'
          onClick={() => openInNewTab('https://www.ovr.ai/')}
        />
        <Provider
          src={PoolDataLogo}
          width='auto'
          height='20px'
          alt='Pool Data'
          onClick={() => openInNewTab('https://pooldata.io/')}
        />
        <Provider
          src={PWNFinanceLogo}
          width='auto'
          height='30px'
          alt='PWN finance'
          className='smaller'
          onClick={() => openInNewTab('https://pwn.finance/')}
        />
        <Provider
          src={KaikoLogo}
          width='auto'
          height='30px'
          alt='Kaiko'
          onClick={() => openInNewTab('https://kaiko.com/')}
        />
        <Provider
          src={KlerosLogo}
          width='auto'
          height='30px'
          alt='Kleros'
          onClick={() => openInNewTab('https://kleros.io/')}
        />
        <Provider
          src={QuantstampLogo}
          width='auto'
          height='30px'
          alt='Quantstamp'
          onClick={() => openInNewTab('https://quantstamp.com/')}
        />
      </PoweredByIron>
    </SponsorContainer>
  )
}

export default SponsorshipInstance
