import React from "react";

function BountiesInstance() {
  return (
    <iframe
      title="Prizes"
      src="https://gitcoin.co/hackathon/sovrython/prizes/"
      style={{
        height: `100%`,
        width: `100%`,
        border: "0px",
      }}
    ></iframe>
  );
}

export default BountiesInstance;
