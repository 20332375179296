import React, { useState, useEffect, useContext } from 'react'
import { FloatingSpaceContext } from '../../contexts/FloatingSpaceContext'

function YoutubeInstance ({ roomData }) {
  const [youtubeRoom, setYoutubeRoom] = useState(
    'PLhM7rBgpVV-JnmRLUbd10ELntN3LFfmKH'
  )
  const { currentFloatingSpaces } = useContext(FloatingSpaceContext)
  const space = currentFloatingSpaces

  useEffect(() => {
    if (space.indexOf('Main Stage') > -1) {
      setYoutubeRoom('PLhM7rBgpVV-JnmRLUbd10ELntN3LFfmKH')
    } else if (space.indexOf('Monge') > -1) {
      setYoutubeRoom('PLSJ8gU1sECuzx4mmVWQ_bw6CLxK6TyPKH')
    } else if (space.indexOf('Saint Germain') > -1) {
      setYoutubeRoom('PL-owlDp9BBasbuWJv-hiLqwqaVrpSoBKj')
    } else if (space.indexOf('Sorbonne') > -1) {
      setYoutubeRoom('PLM-Xjhvin-uWN9ov74srLZIuJsbRSc2ou')
    } else if (space.indexOf('Bievre') > -1) {
      setYoutubeRoom('PLUt355rCCNrSL-n6G7vxavNv7AKdrdfkS')
    } else if (space.indexOf('Pontoise') > -1) {
      setYoutubeRoom('PLqL60kqgLPBBrc64K-1Gs771FBTiLtYZE')
    } else {
      setYoutubeRoom('PLhM7rBgpVV-JnmRLUbd10ELntN3LFfmKH')
    }
    return console.log(youtubeRoom)
  }, [space, youtubeRoom])
  return (
    <iframe
      title='Youtube Livestream'
      width='100%'
      height='100%'
      src={`https://www.youtube-nocookie.com/embed/videoseries?list=${youtubeRoom}`}
      frameBorder='0'
      allow='accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture'
      allowFullScreen
    />
  )
}

export default YoutubeInstance
