import React from 'react'

function StatusInstance () {
  return (
    <iframe
      title='Status.im'
      src='https://join.status.im/eth-cc'
      style={{
        height: `100%`,
        width: `100%`,
        border: '0px',
        background: 'white'
      }}
    ></iframe>
  )
}

export default StatusInstance
