import React from 'react'

function OpenseaInstance () {
  return (
    <iframe
      src='https://opensea.io/assets/0x495f947276749ce646f68ac8c248420045cb7b5e/2107201034281042631091652560662026878143594057930889583581948423216474095617?embed=true'
      width='100%'
      height='100%'
      frameBorder='0'
      allowFullScreen
    ></iframe>
  )
}

export default OpenseaInstance
