import React, { useEffect, useContext, useReducer } from 'react'
import styled from 'styled-components'
import { Rnd } from 'react-rnd'
import { isMobile } from 'react-device-detect'

import { FloatingSpaceContext } from '../contexts/FloatingSpaceContext'

import { RoomNames } from '../utils/constants'
import RoomInstance from './RoomInstance'
import CryptovoxelsInstance from './integrations/CryptovoxelsInstance'
import LoftRadioInstance from './integrations/LoftRadioInstance'
import TypeformInstance from './integrations/TypeformInstance'
import YoutubeInstance from './integrations/YoutubeInstance'
import ChatInstance from './integrations/ChatInstance'
import CalendarInstance from './integrations/CalendarInstance'
import AboutInstance from './external-sites/AboutInstance'
import BountiesInstance from './external-sites/BountiesInstance'
import TwitterInstance from './integrations/TwitterInstance'
import EthCCInstance from './external-sites/EthCCInstance'
import OpenseaInstance from './external-sites/OpenseaInstance'
import SponsorshipInstance from './SponsorshipInstance'
import StatusInstance from './external-sites/StatusInstance'
import PoapInstance from './external-sites/PoapInstance'
import { useState } from 'react/cjs/react.production.min'

const width = window.innerWidth / 2
const height = window.innerHeight / 2

const SpaceHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`
const SpaceHeaderElement = styled.div`
  margin: 0.1rem;
`
const SpaceContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
`

const SpaceContent = styled.div`
  width: 100%;
  height: 100%;
  flex: 1;
`

const spaceContainerStyle = {
  padding: '1rem',
  borderRadius: 10,
  cursor: 'all-scroll',
  pointerEvents: 'all',

  background:
    'linear-gradient(135deg, rgba(111, 109, 126, 0.2) 0%, rgba(255, 255, 255, 0.2) 100%)',
  boxShadow: '0px 12px 24px rgba(8, 9, 12, 0.6)',
  backdropFilter: 'blur(22px)',
  /* Note: backdrop-filter has minimal browser support */

  fontFamily: 'Epilogue',
  fontStyle: 'normal',
  fontWeight: 600,
  fontSize: '14px',
  lineHeight: '18px',
  letterSpacing: '2px',
  textTransform: 'uppercase',
  color: '#fff'
}

const RoomLink = styled.a`
  text-decoration: none;
  padding-left: 1rem;
`

const Closer = styled.div`
  opacity: 0.7;
  cursor: pointer;
  &:hover {
    opacity: 1;
  }
  &::before {
    content: '×'; // here is your X(cross) sign.
    color: #fff;
    font-family: Arial, sans-serif;
    font-weight: bold;
    font-size: 30px;
  }
`

function getFloatingRoomWindow (windowKey) {
  if (windowKey === 'stage') {
    return <YoutubeInstance />
  } else if (RoomNames.indexOf(windowKey) > -1) {
    return <RoomInstance space={windowKey} />
  } else if (windowKey === 'Schedule') {
    return <CalendarInstance />
  } else if (windowKey === 'OpenSea') {
    return <OpenseaInstance />
  } else if (windowKey === 'Poap') {
    return <PoapInstance />
  } else if (windowKey === 'Status Chat') {
    return <StatusInstance />
  } else if (windowKey === 'Sponsorships') {
    return <SponsorshipInstance />
  } else if (windowKey === 'Twitter') {
    return <TwitterInstance />
  } else if (windowKey === 'Main Stage') {
    return <YoutubeInstance />
  } else if (windowKey === 'Monge') {
    return <YoutubeInstance />
  } else if (windowKey === 'Saint Germain') {
    return <YoutubeInstance />
  } else if (windowKey === 'Sorbonne') {
    return <YoutubeInstance />
  } else if (windowKey === 'Bievre') {
    return <YoutubeInstance />
  } else if (windowKey === 'Pontoise') {
    return <YoutubeInstance />
  } else if (windowKey === 'EthCC') {
    return <EthCCInstance />
  } else if (windowKey === null) {
    return null
  }
}

function zIndexesReducer (state, action) {
  return {
    ...state,
    [action.key]: action.value
  }
}

function FloatingRoomWindow () {
  const { currentFloatingSpaces, closeFloatingSpace } = useContext(
    FloatingSpaceContext
  )

  const [zIndexes, setZIndexes] = useReducer(zIndexesReducer, {})
  const maxZ = Object.values(zIndexes).reduce((acc, el) => Math.max(acc, el), 1)

  useEffect(() => {
    let tempMax = maxZ
    currentFloatingSpaces.forEach(space => {
      if (!zIndexes[space]) {
        setZIndexes({ key: space, value: ++tempMax })
      }
    })
  }, [currentFloatingSpaces, maxZ, zIndexes])

  function setWindowFocus (windowKey) {
    setZIndexes({ key: windowKey, value: maxZ + 1 })
  }

  const setStartingCoordinatesX = windowKey => {
    let windowOriginX = 20
    if (windowKey === 'Twitter') {
      windowOriginX = width * 1.11
    } else if (windowKey === 'Schedule') {
      windowOriginX = width / 2
    } else if (windowKey === 'Status Chat') {
      windowOriginX = 10
    } else if (
      windowKey === 'OpenSea' ||
      windowKey === 'Sponsorships' ||
      windowKey === 'EthCC'
    ) {
      windowOriginX = width / 5
    } else if (windowKey === 'credits' || windowKey === 'Sign Up') {
      windowOriginX = width / 4
    } else if (windowKey === 'About' || windowKey === 'claim poap token') {
      windowOriginX = width / 3
    } else if (
      windowKey === 'Sorbonne' ||
      windowKey === 'Bievre' ||
      windowKey === 'Pontoise'
    ) {
      windowOriginX = width
    } else {
      windowOriginX = 20
    }
    return windowOriginX
  }
  const setStartingCoordinatesY = windowKey => {
    let windowOriginY = 40
    if (
      windowKey === 'Monge' ||
      windowKey === 'Bievre' ||
      windowKey === 'Auction'
    ) {
      windowOriginY = height / 2
    } else if (windowKey === 'Main Stage' || windowKey === 'Pontoise') {
      windowOriginY = 20
    } else if (
      windowKey === 'Twitter' ||
      windowKey === 'Status Chat' ||
      windowKey === 'Sponsorships' ||
      windowKey === 'Schedule'
    ) {
      windowOriginY = 55
    } else if (windowKey === 'Saint Germain' || windowKey === 'Sorbonne') {
      windowOriginY = height + 10
    } else if (windowKey === 'Twitter') {
      windowOriginY = height / 2
    } else if (
      windowKey === 'OpenSea' ||
      windowKey === 'livestream' ||
      windowKey === 'claim poap token'
    ) {
      windowOriginY = height / 2 - 70
    } else {
      windowOriginY = 55
    }
    return windowOriginY
  }

  const setFloatingwindowColor = windowKey => {
    let bgColor = '#ffffff00'
    {
      {
        /**
    if (windowKey === 'Partners' || windowKey === 'Workshops') {
      bgColor = '#C1B7A3DD'
    } else if (
      windowKey === 'Main Stage' ||
      windowKey === 'Monge' ||
      windowKey === 'Saint Germain' ||
      windowKey === 'Sorbonne' ||
      windowKey === 'Bievre' ||
      windowKey === 'Pontoise'
    ) {
      bgColor = '#b30602DD'
    } else if (windowKey === 'Status Chat' || windowKey === 'OpenSea') {
      bgColor = '#082d75DD'
    } else if (windowKey === 'Schedule' || windowKey === 'Sponsorships') {
      bgColor = '#E6E6E6DD'
    } else {
      bgColor = '#082d75DD'
    }
*/
      }
    }
    return bgColor
  }

  const setStartingWidth = windowKey => {
    let windowWidth = width - 20
    if (windowKey === 'Status Chat') {
      windowWidth = width / 1.3
    } else if (
      windowKey === 'Sponsorships' ||
      windowKey === 'OpenSea' ||
      windowKey === 'EthCC'
    ) {
      windowWidth = width * 1.6
    } else if (windowKey === 'Twitter') {
      windowWidth = width * 0.8
    } else {
      windowWidth = width - 20
    }
    return windowWidth
  }

  const setStartingHeight = windowKey => {
    let windowHeight = height - 20
    if (
      windowKey === 'Schedule' ||
      windowKey === 'Twitter' ||
      windowKey === 'EthCC'
    ) {
      windowHeight = height * 1.8
    } else if (
      windowKey === 'Status Chat' ||
      windowKey === 'Sponsorships' ||
      windowKey === 'OpenSea'
    ) {
      windowHeight = height * 1.3
    } else {
      windowHeight = height - 20
    }
    return windowHeight
  }

  return currentFloatingSpaces.map(windowKey => (
    <Rnd
      key={windowKey}
      default={{
        x: setStartingCoordinatesX(windowKey),
        y: setStartingCoordinatesY(windowKey),
        width: setStartingWidth(windowKey),
        height: setStartingHeight(windowKey)
      }}
      style={{
        ...spaceContainerStyle,
        backgroundColor: setFloatingwindowColor(windowKey),
        zIndex: zIndexes[windowKey] || 1
      }}
      onDragStart={() => setWindowFocus(windowKey)}
    >
      <SpaceContainer>
        <SpaceHeader>
          <SpaceHeaderElement onTap={() => closeFloatingSpace(windowKey)}>
            <Closer onClick={() => closeFloatingSpace(windowKey)} />
          </SpaceHeaderElement>

          <SpaceHeaderElement style={{ color: '#fff' }}>
            {windowKey}
          </SpaceHeaderElement>
          <SpaceHeaderElement></SpaceHeaderElement>
        </SpaceHeader>
        <SpaceContent>{getFloatingRoomWindow(windowKey)}</SpaceContent>
      </SpaceContainer>
    </Rnd>
  ))
}

export default FloatingRoomWindow
